'use client'
import { Button, IconButton, InputAdornment, TextField, Typography, Link as MuiLink, CircularProgress } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from "./index.module.css"
import { useEffect, useState } from "react";
import { AuthError } from "aws-amplify/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { login, useDispatch } from "../../state";
import { Routes } from "../../util";

export default function LoginTab(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const searchParams = useSearchParams()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [shouldRegister, setShouldRegister] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSignIn = async () => {
    try {
      setLoading(true)
      await dispatch(login({ username: email, password, vanity: "init" }))
      const redirectQueryParam = searchParams[0].get("redirect")
      const redirectPath = redirectQueryParam || Routes.HOME
      navigate(redirectPath)
    } catch (error: unknown | AuthError) {
      if (error instanceof AuthError) {
        if (error.name === "UserNotFoundException") {
          setShouldRegister(true)
        }
        if (error.name === "NotAuthorizedException") {
          setPasswordError("Incorrect password")
        }
      }
    } finally {
      setLoading(false)
    }
  };

  // Global keydown listener for Enter
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSignIn();
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [handleSignIn]);

  return (
    <>
        <Typography>
          Log In to Your User Account
        </Typography>
        {shouldRegister && <Typography color="error" variant="subtitle1">
          User not found, please register an account.
        </Typography>
        }
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          variant="standard"
          InputProps={{
            startAdornment: <InputAdornment position="start"><EmailIcon color="primary" /></InputAdornment>
          }}
        />
        <TextField
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          variant="standard"
          error={Boolean(passwordError)}
          helperText={passwordError || undefined}
          type={showPassword ? "text" : "password"}
          InputProps={{
            startAdornment: <InputAdornment position="start"><LockIcon color="primary" /></InputAdornment>,
            endAdornment: <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          }}
        />
      <MuiLink
        className={styles.forgotPassword}
        href={Routes.FORGOT_PASSWORD}
      >
        Forgot Password?
      </MuiLink>
      <Button
        className={styles.loginButton}
        fullWidth
        disabled={loading}
        variant="contained"
        color="primary"
        onClick={handleSignIn}>
        {loading ? <CircularProgress size={20} /> : "Log In"}
      </Button>
    </>
  );
}

import { Typography, TextField, Grid, MenuItem, Divider, Button, Box } from "@mui/material";
import type { CustomerCreate } from "orderflow-lambdas";
import usaStates from "../../../../static/usaStates.json";
import { textFieldProps, textFieldSelectProps } from "../../constants/form";
import { useEffect, useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import { FormikProps } from "formik";
import { getFormikField } from "../../../../util/fornik-helpers";
import { useTranslation } from "react-i18next";

export default function BillingAndDelivery({
    formik,
    viewMode,
}: {
    formik: FormikProps<CustomerCreate>;
    viewMode: boolean;
}): JSX.Element {
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tAddC } = useTranslation([], { keyPrefix: "team.Modals.Customers.createAndEdit.Add Customer" })
    const { t: tAddCButtons } = useTranslation([], { keyPrefix: "team.Modals.Customers.createAndEdit.Add Customer.Buttons" })

    const [billingCopied, setBillingCopied] = useState(false);
    const [deliveryCopied, setDeliveryCopied] = useState(false);

    const copyCustomerIntoBilling = () => {
        const { other, website, ...customerData } = formik.values.details; // removing unneeded fields

        formik.setValues({
            ...formik.values,
            billing: { ...customerData }
        });
    };

    const copyCustomerIntoDelivery = () => {
        const { other, website, ...customerData } = formik.values.details; // removing unneeded fields

        formik.setValues({
            ...formik.values,
            delivery: { ...customerData }
        });
    };

    useEffect(() => {
        // Watching the billing and delivery objects and compare them to the customer object
        const { other, website, ...customerData } = formik.values.details; // removing unneeded fields. Its important do do it here too so if statments work good when compare data
        const billingData = formik.values.billing;
        const deliveryData = formik.values.delivery;

        if (billingCopied && JSON.stringify(billingData) !== JSON.stringify(customerData)) {
            setBillingCopied(false);
        }
        if (deliveryCopied && JSON.stringify(deliveryData) !== JSON.stringify(customerData)) {
            setDeliveryCopied(false);
        }
    }, [formik.values.billing, formik.values.delivery, formik.values.details, billingCopied, deliveryCopied]);


    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "2px" }}>
                <Typography color="primary">{tAddC("BILLING")}</Typography>
                {billingCopied ? (
                    <Button disabled={viewMode} variant="outlined" size="small" color="success" startIcon={<CheckIcon />}>{tAddCButtons("Copied")}</Button>
                ) : (
                    <Button disabled={viewMode} variant="outlined" size="small" color="primary" onClick={() => { copyCustomerIntoBilling(); setBillingCopied(true) }}>{tAddCButtons("Copy CUSTOMER Details")}</Button>
                )}
            </Box >
            <Divider sx={{ marginBottom: 1 }} />
            <div className="grid grid-flow-col grid-rows-6 gap-4 webkit-box">
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tAddC("Company Name")} name="billing.companyName" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.companyName').error)}
                        helperText={getFormikField(formik, 'billing.companyName').error}
                        value={getFormikField(formik, 'billing.companyName').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Address")} name="billing.address" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.address').error)}
                        helperText={getFormikField(formik, 'billing.address').error}
                        value={getFormikField(formik, 'billing.address').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("City")} name="billing.city" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.city').error)}
                        helperText={getFormikField(formik, 'billing.city').error}
                        value={getFormikField(formik, 'billing.city').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("State")} select name="billing.state" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.state').error)}
                        helperText={getFormikField(formik, 'billing.state').error}
                        value={getFormikField(formik, 'billing.state').value} onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {usaStates.map((state) => (
                            <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                {state.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Zip")} name="billing.zipCode" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.zipCode').error)}
                        helperText={getFormikField(formik, 'billing.zipCode').error}
                        value={getFormikField(formik, 'billing.zipCode').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField disabled variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("Country")} select name="billing.country"
                        error={Boolean(getFormikField(formik, 'billing.country').error)}
                        helperText={getFormikField(formik, 'billing.country').error}
                        value={getFormikField(formik, 'billing.country').value} onChange={formik.handleChange}
                    >
                        <MenuItem value="US">US</MenuItem>
                    </TextField>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tAddC("Contact Name")} name="billing.contactName" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.contactName').error)}
                        helperText={getFormikField(formik, 'billing.contactName').error}
                        value={getFormikField(formik, 'billing.contactName').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Tel. No.")} name="billing.phone" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.phone').error)}
                        helperText={getFormikField(formik, 'billing.phone').error}
                        value={getFormikField(formik, 'billing.phone').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Email")} name="billing.email" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.email').error)}
                        helperText={getFormikField(formik, 'billing.email').error}
                        value={getFormikField(formik, 'billing.email').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Preferred Method of Communication")} name="billing.preferredCommunication" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.preferredCommunication').error)}
                        helperText={getFormikField(formik, 'billing.preferredCommunication').error}
                        value={getFormikField(formik, 'billing.preferredCommunication').value} onChange={formik.handleChange}
                    />
                </div>
            </div>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 2, marginBottom: "2px" }}>
                <Typography color="primary">DELIVERY</Typography>
                {deliveryCopied ? (
                    <Button disabled={viewMode} variant="outlined" size="small" color="success" startIcon={<CheckIcon />}>{tAddCButtons("Copied")}</Button>
                ) : (
                    <Button disabled={viewMode} variant="outlined" size="small" color="primary" onClick={() => { copyCustomerIntoDelivery(); setDeliveryCopied(true) }}>{tAddCButtons("Copy CUSTOMER Details")}</Button>
                )}
            </Box >
            <Divider sx={{ marginBottom: 1 }} />
            <div className="grid grid-flow-col grid-rows-6 gap-4 webkit-box">
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tAddC("Company Name")} name="delivery.companyName" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'delivery.companyName').error)}
                        helperText={getFormikField(formik, 'delivery.companyName').error}
                        value={getFormikField(formik, 'delivery.companyName').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Address")} name="delivery.address" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'delivery.address').error)}
                        helperText={getFormikField(formik, 'delivery.address').error}
                        value={getFormikField(formik, 'delivery.address').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("City")} name="delivery.city" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'delivery.city').error)}
                        helperText={getFormikField(formik, 'delivery.city').error}
                        value={getFormikField(formik, 'delivery.city').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("State")} select name="delivery.state" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'delivery.state').error)}
                        helperText={getFormikField(formik, 'delivery.state').error}
                        value={getFormikField(formik, 'delivery.state').value} onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {usaStates.map((state) => (
                            <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                {state.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Zip")} name="delivery.zipCode" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'delivery.zipCode').error)}
                        helperText={getFormikField(formik, 'delivery.zipCode').error}
                        value={getFormikField(formik, 'delivery.zipCode').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField disabled variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("Country")} select name="delivery.country"
                        error={Boolean(getFormikField(formik, 'delivery.country').error)}
                        helperText={getFormikField(formik, 'delivery.country').error}
                        value={getFormikField(formik, 'delivery.country').value} onChange={formik.handleChange}
                    >
                        <MenuItem value="US">US</MenuItem>
                    </TextField>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tAddC("Contact Name")} name="delivery.contactName" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'delivery.contactName').error)}
                        helperText={getFormikField(formik, 'delivery.contactName').error}
                        value={getFormikField(formik, 'delivery.contactName').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Tel. No.")} name="delivery.phone" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'delivery.phone').error)}
                        helperText={getFormikField(formik, 'delivery.phone').error}
                        value={getFormikField(formik, 'delivery.phone').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Email")} name="delivery.email" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'delivery.email').error)}
                        helperText={getFormikField(formik, 'delivery.email').error}
                        value={getFormikField(formik, 'delivery.email').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Preferred Method of Communication")} name="delivery.preferredCommunication" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'delivery.preferredCommunication').error)}
                        helperText={getFormikField(formik, 'delivery.preferredCommunication').error}
                        value={getFormikField(formik, 'delivery.preferredCommunication').value} onChange={formik.handleChange}
                    />
                </div>
            </div>
        </>
    );
}

import * as Yup from 'yup';

export const orderSchema = Yup.object().shape({
  rate: Yup.number()
    .min(0, "Rate must 0 to 100!")
    .max(100, "Rate must 0 to 100!")
    .required('Required'),
  orderDate: Yup.string()
    .min(2, 'Required')
    .required('Required'),
  estimatedShipDate: Yup.string()
    .min(2, 'Required')
    .required('Required'),
  customerDeposit: Yup.number()
    .required('Required'),
  shipMethod: Yup.string()
    .min(0, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  deadline: Yup.string()
    .min(2, 'Required')
    .required('Required'),
  paymentTerms: Yup.array()
    .min(1, "Select Payment Method!")
    .required('Required'),
  customer: Yup.object({
    companyName: Yup.string()
      .required('Required'),
  })
})
import { useEffect } from 'react';
import { Box, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import type { ItemSearchInput } from 'orderflow-lambdas';
import { useDispatch, useSelector } from '../../../../state';
import { useTranslation } from 'react-i18next';
import { buttonWidth } from '../../constants/form';
import CloseIcon from '@mui/icons-material/Close';
import { closeModal, searchItems } from '../../../../state/itemSearchModalSlice';
import Table from './Table';

export default function ItemSearchModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: 'team.Global.Buttons' })
    const { t: tMIS } = useTranslation([], { keyPrefix: 'team.Modals.ItemsSearch' })

    const { selectedItems, openItemSearchModal, loading, onImport, onCancel } = useSelector(({ itemSearchModalSlice }) => itemSearchModalSlice);
    const { team } = useSelector(({ userSlice }) => userSlice);


    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeModal());
        if (onCancel) onCancel()
    };
    const importItems = () => {
        dispatch(closeModal());
        if (onImport) onImport(selectedItems)
    };

    const initialResults = async () => {
        const payload: ItemSearchInput = { query: '', searchBy: 'vendorName', size: 100, page: 0 }
        await dispatch(searchItems(payload))
    }

    useEffect(() => {
        if (!openItemSearchModal || team === "init" || team === "loading") return;
        const fetchData = async () => {
            await initialResults();
        };
        fetchData();
    }, [openItemSearchModal, team]);

    return (
        <Dialog
            open={openItemSearchModal}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick')
                    return;
                handleClose()
            }}
            maxWidth='lg'
            scroll='paper'
            PaperProps={{ className: 'w-full' }}
        >
            <DialogTitle sx={{ m: 0, p: 1 }}>{tMIS("Browse Item")}</DialogTitle>
            <IconButton disabled={loading} color='primary' sx={{ position: 'absolute', right: 4, top: 4 }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 1 }} >
                {loading ? (
                    <Box height={800} display='flex' justifyContent='center' alignItems='center'>
                        <CircularProgress size={80} />
                    </Box>
                ) : (
                    <Box sx={{ height: 800, width: '100%' }}>
                        <Table />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Box width='100%' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button disabled={loading} variant='contained' color='primary' sx={{ width: buttonWidth }} onClick={importItems}>{loading ? <CircularProgress size={20} /> : tGlobal('Import')}</Button>
                    <Button disabled={loading} variant='outlined' color='primary' sx={{ width: buttonWidth }} onClick={handleClose}>{tGlobal('Cancel')}</Button>
                </Box>
            </DialogActions>
        </Dialog >
    );
}

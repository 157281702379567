import * as Yup from 'yup';

export const detailsSchema = Yup.object().shape({
  category: Yup.string()
    .max(255, 'Too Long!'),
  taxable: Yup.boolean(),
  commissionable: Yup.boolean(),
  assemblyItem: Yup.boolean(),
  description: Yup.string()
    .max(255, 'Too Long!')
    .required('Required'),
  itemLink: Yup.string()
    .max(255, 'Too Long!'),
  vendorCost: Yup.string()
    .max(255, 'Too Long!'),
  customerPrice: Yup.string()
    .max(255, 'Too Long!')
    .required('Required'),
  primaryVendor: Yup.string()
    .max(255, 'Too Long!'),
  vendorItemNumber: Yup.string()
    .max(255, 'Too Long!')
    .required('Required'),
  customerItemNumber: Yup.string()
    .max(255, 'Too Long!'),
  unit: Yup.string()
    .max(255, 'Too Long!')
    .required('Required'),
  minimumRequirements: Yup.string()
    .max(255, 'Too Long!'),
  averageCost: Yup.string()
    .max(255, 'Too Long!'),
  reorder: Yup.string()
    .max(255, 'Too Long!'),
  onHand: Yup.string()
    .max(255, 'Too Long!'),
  backOrder: Yup.string()
    .max(255, 'Too Long!'),
  onOrder: Yup.string()
    .max(255, 'Too Long!'),
})

export const billingAndDeliverySchema = Yup.object().shape({
  companyName: Yup.string()
    .max(255, 'Too Long!'),
  contactName: Yup.string()
    .max(255, 'Too Long!'),
  address: Yup.string()
    .max(255, 'Too Long!'),
  city: Yup.string()
    .max(255, 'Too Long!'),
  state: Yup.string()
    .max(255, 'Too Long!'),
  zipCode: Yup.string()
    .max(255, 'Too Long!'),
  country: Yup.string()
    .max(255, 'Too Long!'),
  position: Yup.string()
    .max(255, 'Too Long!'),
  email: Yup.string()
    .email('Invalid email'),
  phone: Yup.string()
    .max(255, 'Too Long!'),
})

export const paymentSchema = Yup.object().shape({
  currentBalance: Yup.string()
    .max(255, 'Too Long!'),
})

export const itemSchema = Yup.object().shape({
  details: detailsSchema,
  billing: billingAndDeliverySchema,
  delivery: billingAndDeliverySchema,
  payment: paymentSchema,
  notes: Yup.string()
    .max(1500, 'Too Long!'),
});

export const vendorSchema = Yup.object().shape({
  details: detailsSchema,
  billing: billingAndDeliverySchema,
  delivery: billingAndDeliverySchema,
  payment: paymentSchema,
  notes: Yup.string()
    .max(1500, 'Too Long!'),
});

export const vendorContactSchema = Yup.object().shape({
  contactName: Yup.string()
    .max(255, 'Too Long!'),
  address: Yup.string()
    .max(255, 'Too Long!'),
  city: Yup.string()
    .max(255, 'Too Long!'),
  state: Yup.string()
    .max(255, 'Too Long!'),
  zipCode: Yup.string()
    .max(255, 'Too Long!'),
  country: Yup.string()
    .max(255, 'Too Long!'),
  position: Yup.string()
    .max(255, 'Too Long!'),
  email: Yup.string()
    .email('Invalid email'),
  phone: Yup.string()
    .max(255, 'Too Long!'),
  preferredCommunication: Yup.string()
    .max(255, 'Too Long!'),
});

export const itemAssemblySchema = Yup.object().shape({
  sequence: Yup.string()
    .max(255, 'Too Long!')
    .min(1, 'Minimum 1')
    .required('Required'),
  itemNumber: Yup.string()
    .max(255, 'Too Long!'),
  componentDescription: Yup.string()
    .max(255, 'Too Long!'),
  quantityRequired: Yup.string()
    .max(255, 'Too Long!'),
  unitCost: Yup.string()
    .max(255, 'Too Long!'),
  extendedPrice: Yup.string()
    .max(255, 'Too Long!'),
});

export const itemPriceBreakSchema = Yup.object().shape({
  quantity: Yup.string()
    .max(255, 'Too Long!')
    .min(1, 'Minimum 1'),
  unitCost: Yup.string()
    .max(255, 'Too Long!'),
  unitPrice: Yup.string()
    .max(255, 'Too Long!'),
});
import { Typography, Divider, Box, Tooltip, useTheme, IconButton, CircularProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { DataGridPro, GridColDef, GridRowModel, useGridApiRef } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormikProps } from "formik";
import { ItemCreate, } from "orderflow-lambdas";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../../state";
import { ItemPriceBreak } from "../../../../../../../packages/orderflow-lambdas/src/shared/api";

const columnPadding = "0 2px"

// We create a new type and add id in it because MUI DataGrid works only with ID
type PriceBreakWithId = ItemPriceBreak & { id: string };

export default function PriceBreak({
    formik,
    viewMode,
}: {
    formik: FormikProps<ItemCreate>;
    viewMode: boolean;
}): JSX.Element {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tAddPB } = useTranslation([], { keyPrefix: "team.Modals.Items.createAndEdit.Add Item" })

    // Basically MUI Grid requires Id to handle rows, but provided api structure does not have ids.
    // Because of that we need to create this state and use it for table.
    // In the functions below we update formik on every event. Basically we update 2 arrays at the same time (one has ids).
    const [priceBreaks, setPriceBreaks] = useState<PriceBreakWithId[]>([]);
    const apiRef = useGridApiRef();
    const { loading } = useSelector(({ vendorModalSlice }) => vendorModalSlice);

    const columns: GridColDef[] = [
        { field: "quantity", headerName: "Quanity", flex: 2, type: "number", editable: true },
        { field: "cost", headerName: "Unit Cost", flex: 2, type: "number", editable: true },
        { field: "price", headerName: "Unit Price", flex: 2, type: "number", editable: true },
        {
            field: "actions",
            headerName: tGlobal("Actions"),
            flex: 1,
            renderCell: (params) => {
                const handleDelete = () => {
                    handleDeleteAssembly(params.id)
                };

                return (
                    <div>
                        <IconButton disabled={viewMode} onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        }
    ];

    const genUUID = () => {
        let uuid = uuidv4()
        return uuid
    }

    // DELETE
    const handleDeleteAssembly = (activeId: any) => {
        apiRef.current.updateRows([{ id: activeId, _action: 'delete' }]);
        formik.setFieldValue('priceBreaks', priceBreaks.filter((item) => item.id !== activeId));
    };

    // EDIT

    const processRowUpdate = (newRow: GridRowModel) => {
        // Find the original row in items
        const originalRow = priceBreaks.find((pB) => pB.id === newRow.id);

        if (!originalRow) {
            throw new Error('Original row not found.');
        }

        // Merge the original row with the updated data
        const updatedRow = {
            ...originalRow,
            ...newRow, // Update fields from newRow
            isNew: false, // Ensure isNew is set to false
        };

        const updatedPriceBreaks = priceBreaks.map((pB) =>
            pB.id === newRow.id ? updatedRow : pB
        );

        // Update the state with the modified row
        setPriceBreaks(updatedPriceBreaks);

        // Update Formik with the updated priceBreaks
        formik.setFieldValue('priceBreaks', updatedPriceBreaks);

        return updatedRow;
    };

    // Add
    const handleAddPriceBreak = () => {
        const emptyRow = { id: uuidv4(), quantity: 0, cost: 0, price: 0 }
        apiRef.current.updateRows([emptyRow])
        const tableState = Array.from(apiRef.current.getRowModels())
        const newState = tableState.map(x => x[1])
        formik.setFieldValue("priceBreaks", newState.map(({ id, ...rest }) => rest))
    };

    useEffect(() => {
        const updatedPriceBreaks = formik.values.priceBreaks?.map(priceBreak => ({
            ...priceBreak,
            id: genUUID()
        }));
        setPriceBreaks(updatedPriceBreaks)
    }, [formik.values.priceBreaks]);


    return (
        <>
            <Typography color="primary">{tAddPB("Price Break")}</Typography>
            <Divider sx={{ marginBottom: 1 }} />
            {loading ? (
                <Box height="400px" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress size={80} />
                </Box>
            ) : (
                <>
                    <Box sx={{ display: "flex", justifyContent: "center", height: 400, paddingTop: "2rem" }}>
                        <DataGridPro
                            // Turn off row/cell selection on click
                            disableRowSelectionOnClick={viewMode}
                            // Disable column menu
                            disableColumnMenu={viewMode}
                            // Hide the density selector
                            disableDensitySelector={viewMode}
                            // Hide the column selector
                            disableColumnSelector={viewMode}
                            // Optionally disallow multiple-row selection
                            disableMultipleRowSelection={viewMode}
                            // Return false if `viewMode` so cells cannot go into edit mode
                            isCellEditable={(params) => !viewMode}
                            apiRef={apiRef}
                            columns={columns}
                            rows={priceBreaks.map((priceBreak) => ({
                                ...priceBreak,
                                id: priceBreak.id
                            }))}
                            sortModel={[{ field: 'quantity', sort: 'asc' }]}
                            localeText={{ footerTotalRows: 'Price Breaks' }}
                            processRowUpdate={processRowUpdate}
                        >
                        </DataGridPro>
                        <Box>
                            <Box sx={{ paddingY: 1, display: "flex", justifyContent: "space-between" }}>
                                <Tooltip title={tAddPB("Add Price Break")}>
                                    <IconButton disabled={viewMode}>
                                        <AddIcon onClick={handleAddPriceBreak} cursor={"pointer"} fontSize={"large"} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box >
                </>
            )}
        </>
    );
}


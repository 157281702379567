import * as React from 'react';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridColumnMenuProps,
  GridColumnMenu,
  GridLoadingOverlayProps,
  LoadingOverlayPropsOverrides,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { Button, CircularProgress, debounce, LinearProgress } from '@mui/material';
import HeaderElipse from './headerElipse';
import RowButton from './rowButton';
import DropDown from './dropDown';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useDispatch, useSelector } from '../../state';
import { setSearchInput, searchItems, setLoading } from '../../state/itemBrowseSlice';
import { ItemSearchInput } from 'orderflow-lambdas';
import { openItemPopupModal } from '../../state/itemPopupModalSlice';
import { topNavBarHeight } from '../NavBars/constants';
import { teams } from '../../../../../packages/orderflow-lambdas/src/shared/services';
import { stringify } from 'querystring';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface LoaderProps extends Omit<GridLoadingOverlayProps, "color">, LoadingOverlayPropsOverrides {
  color?: string;
}

const Loader = (props: LoaderProps) => {
  return <LinearProgress color={"primary"} />;
}

function CustomToolbar() {
  const dispatch = useDispatch()
  const { t: tGB } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
  const { t: tCV } = useTranslation([], { keyPrefix: "team.Components.ItemBrowseTable" })
  const { searchInput, searchBy, loading } = useSelector((store) => store.itemBrowseSlice)
  const { team } = useSelector((store) => store.userSlice)

  const debouncedSearch = useCallback(
    debounce((searchQuery: string) => {
      const payload: ItemSearchInput = { query: searchQuery, searchBy, size: 100, page: 0 }
      dispatch(searchItems(payload))
    }, 1500),
    []
  );

  if (team === "init" || team === "loading") return null

  return (
    <GridToolbarContainer>
      <DropDown />
      <TextField
        value={searchInput}
        onChange={(e) => {
          dispatch(setLoading(true))
          dispatch(setSearchInput(e.currentTarget.value))
          debouncedSearch(e.currentTarget.value)
        }}
        id="filled-basic"
        label={tCV("Search for items...")}
        variant="outlined"
        InputProps={{
          startAdornment: <InputAdornment position='start'><SearchOutlinedIcon /></InputAdornment>,
          endAdornment: <React.Fragment>
            {loading ? <CircularProgress color='inherit' size={20} /> : null}
          </React.Fragment>
        }}
      />
      <Button
        variant='outlined'
        color='primary'
        onClick={() => {
          const payload: ItemSearchInput = { query: searchInput, searchBy: "vendorItemNumber", size: 100, page: 0 }
          dispatch(searchItems(payload))
        }}
      >{tGB('Search')}</Button>
      <Box sx={{ flexGrow: 1 }} />
      <button
        className='text-blue-600 border-0 bg-inherit hover:cursor-pointer' onClick={() => { dispatch(openItemPopupModal()) }}>
        {tGB('+ ADD')}</button>
      <GridToolbarExport
        slotProps={{
          tooltip: { title: 'Export data' },
        }}
      />
      <HeaderElipse />
    </GridToolbarContainer>
  );
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
        columnMenuFilterItem: null
      }}
    />
  );
}

export default function DataTables() {
  const dispatch = useDispatch();
  const { t: tGC } = useTranslation([], { keyPrefix: 'team.Global.Common' })
  const { t: tCV } = useTranslation([], { keyPrefix: "team.Components.ItemBrowseTable" })
  const { results, loading } = useSelector((store) => store.itemBrowseSlice);
  const { team } = useSelector((store) => store.userSlice);

  const initialResults = async () => {
    if (team === "init" || team === "loading") return null
    const payload: ItemSearchInput = { query: '', searchBy: 'vendorName', size: 100, page: 0 }
    await dispatch(searchItems(payload))
  }

  React.useEffect(() => {
    initialResults()
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: tCV("Item ID"),
      type: 'string',
      width: 300,
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params.value}
        </span>
      )
    },
    {
      field: 'vendorItemNumber',
      headerName: tCV("Vendor Item Number"),
      type: 'string',
      width: 160,
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params.value}
        </span>
      )
    },
    {
      field: 'customerPrice',
      headerName: tCV('Customer Price'),
      width: 140,
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params.value}
        </span>
      )
    },
    {
      field: 'unit',
      headerName: tCV('Unit'),
      width: 110,
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params.value}
        </span>
      )
    },
    {
      field: 'commissionable',
      headerName: tCV('Comissionable'),
      width: 130,
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {JSON.stringify(params.value)}
        </span>
      )
    },
    {
      field: 'assemblyItem',
      headerName: tCV('Assembly Item'),
      type: 'string',
      width: 130,
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {JSON.stringify(params.value)}
        </span>
      )
    },
    {
      field: 'minimumRequirements',
      headerName: tCV('Minimum Requirements'),
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params.value}
        </span>
      )
    },
    {
      field: 'taxable',
      headerName: tGC('Taxable'),
      width: 120,
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {JSON.stringify(params.value)}
        </span>
      )
    },
    {
      field: 'description',
      headerName: tGC('Description'),
      type: 'string',
      width: 160,
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params.value}
        </span>
      )
    },
    {
      field: 'action',
      headerName: '',
      width: 55,
      resizable: false,
      sortable: false,
      renderCell: params => {
        return <RowButton item={params.row.item}></RowButton>
      },
    }
  ];

  return (
    <div style={{ height: `calc(100vh - ${topNavBarHeight} - 250px)` }}>
      <DataGridPro
        sx={{
          border: 'none', padding: '1rem', '.MuiDataGrid-row:nth-of-type(even)': {
            backgroundColor: '#ECECEC;',
          }, '.MuiDataGrid-scrollbar--horizontal': { display: 'none' }, '.MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' }
        }}
        rows={results ? results.results.map((result) => {
          return {
            id: result.ItemId,
            vendorItemNumber: result.details.vendorItemNumber,
            description: result.details.description,
            taxable: result.details.taxable,
            customerPrice: result.details.customerPrice,
            unit: result.details.unit,
            commissionable: result.details.commissionable,
            assemblyItem: result.details.assemblyItem,
            minimumRequirements: result.details.minimumRequirements,
            item: result
          }
        }) : []}

        loading={loading}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pinnedColumns={{ right: ["action"] }}
        pageSizeOptions={[1]}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnSorting
        disableColumnMenu
        autoHeight={false}
        slots={{
          loadingOverlay: Loader,
          toolbar: CustomToolbar,
          columnMenu: CustomColumnMenu
        }}
      />
    </div>
  );
}
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { searchOrders, setSearchBy } from '../../state/orderBrowseSlice';
import { useDispatch, useSelector } from '../../state';
import { OrderSearchInput } from 'orderflow-lambdas';
import { useTranslation } from 'react-i18next';

export default function BasicSelect() {
  const dispatch = useDispatch();
  const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
  const { t: tGT } = useTranslation([], { keyPrefix: "team.Global.Tables" })
  const { t: tCO } = useTranslation([], { keyPrefix: "team.Components.OrderBrowseTable" })

  const { searchInput,searchBy } = useSelector((store) => store.orderBrowseSlice);

  const handleChange = (event: SelectChangeEvent) => {
    const searchValue = event.target.value as OrderSearchInput['searchBy']
    dispatch(setSearchBy(searchValue))
    const payload: OrderSearchInput = { query: searchInput, searchBy: searchValue, size: 100, page: 0 }
    dispatch(searchOrders(payload))
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{tGT("Search By")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={searchBy}
          label={tGT("Search By")}
          onChange={handleChange}
        >
          <MenuItem value={'companyName'}>{tGC("Customer")}</MenuItem>
          <MenuItem value={'customerId'}>{tCO("Customer Id")}</MenuItem>
          <MenuItem value={'orderCode'}>{tCO("Order No.")}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
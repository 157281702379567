import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { OrderDeleteInput } from "orderflow-lambdas"
import ApiClient from "../../util/api-client"

export interface OrderPopupModalState {
  openOrderPopupModal: boolean
  loading: boolean
}

// Initialize state
const initialState: OrderPopupModalState = {
  openOrderPopupModal: false,
  loading: false
}

export const orderPopupModalSlice = createSlice({
  name: "orderPopupModal",
  initialState,
  reducers: {
    openOrderPopupModal: (state) => ({
      ...state,
      openOrderPopupModal: true,
    }),
    closeModal: () => ({
      ...initialState
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
})

export const {
  openOrderPopupModal,
  closeModal,
  setLoading
} = orderPopupModalSlice.actions
export default orderPopupModalSlice.reducer

// Thunks
export const deleteOrder = (deleteInput: OrderDeleteInput) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setLoading(true))
    await ApiClient.deleteOrder.mutate(deleteInput)
  } finally {
    dispatch(setLoading(false))
  }
}

import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { Item, ItemSearchOutput, ItemSearchInput } from "orderflow-lambdas"
import ApiClient from "../../util/api-client"
type SearchBy = ItemSearchInput['searchBy']

export interface ItemSearchState {
  searchInput: string
  openItemSearchModal: boolean
  resp?: ItemSearchOutput
  searchBy: SearchBy
  loading: boolean,
  searchLoading: boolean,
  selectedItems: Item[];
  onImport?: (selectedItems: Item[]) => void
  onCancel?: () => void
}

// Initialize state
const initialState: ItemSearchState = {
  searchInput: '',
  openItemSearchModal: false,
  resp: undefined,
  searchBy: 'vendorName',
  loading: false,
  searchLoading: false,
  selectedItems: [],
  onImport: undefined,
  onCancel: undefined
}

export const ItemSearchModalSlice = createSlice({
  name: "ItemSearch",
  initialState,
  reducers: {
    openSearchItemModal: (state, action: PayloadAction<{ onImport?: (selectedItems: Item[]) => void , onCancel?: () => void}>) => ({
      ...initialState,
      openItemSearchModal: true,
      onImport: action.payload.onImport,
      onCancel: action.payload.onCancel
    }),
    closeModal: (state) => ({

      ...state,
      openItemSearchModal: false
    }),
    setSearchInput: (state, action: PayloadAction<string>) => ({
      ...state,
      searchInput: action.payload
    }),
    setSearchBy: (state, action: PayloadAction<SearchBy>) => ({
      ...state,
      searchBy: action.payload
    }),
    setResults: (state, action: PayloadAction<ItemSearchOutput | undefined>) => ({
      ...state,
      resp: action.payload
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload
    }),
    setSearchLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      searchLoading: action.payload
    }),
    setSelectedItems: (state, action: PayloadAction<Item[]>) => {
      state.selectedItems = action.payload;
    },
  },
})

export const {
  openSearchItemModal,
  closeModal,
  setSearchInput,
  setSearchBy,
  setResults,
  setLoading,
  setSearchLoading,
  setSelectedItems,
} = ItemSearchModalSlice.actions
export default ItemSearchModalSlice.reducer

export const searchItems = (searchInput: ItemSearchInput) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setSearchLoading(true))
    const resp = await ApiClient.searchItems.query(searchInput)
    dispatch(setResults(resp))
  } catch (error: unknown) {
    dispatch(setResults(undefined))
  } finally {
    dispatch(setSearchLoading(false))
  }
}

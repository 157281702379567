import { Typography, TextField, Grid, MenuItem, Divider, Stack } from "@mui/material";
import type { VendorTerms, VendorCreate } from "orderflow-lambdas";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import usaStates from "../../../../static/usaStates.json";
import { gridItemProps, textFieldProps, textFieldSelectProps } from "../../constants/form";
import dayjs, { Dayjs } from "dayjs";
import { FormikProps } from "formik";
import { getFormikField } from "../../../../util/fornik-helpers";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function VendorAndPayment({
    formik,
    vendorTerms,
    viewMode,
}: {
    formik: FormikProps<VendorCreate>;
    vendorTerms: VendorTerms[];
    viewMode: boolean;
}): JSX.Element {
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tAddV } = useTranslation([], { keyPrefix: "team.Modals.Vendors.createAndEdit.Add Vendor" })
    const lastOrderDate = formik.values.payment?.lastOrderDate ? dayjs.unix(formik.values.payment.lastOrderDate) : null;

    const handleChangeLastOrderDate = (newValue: Dayjs | null) => {
        // Convert the new date value to Unix time (seconds since the Unix Epoch)
        const unixTime = newValue ? newValue.unix() : null;

        // Update Formik's state with Unix time
        formik.setFieldValue("payment.lastOrderDate", unixTime);
    };

    useEffect(() => {
        if (vendorTerms.length > 0) {
            const defaultTerm = vendorTerms.find((vT) => vT.default);
            if (defaultTerm) {
                formik.setFieldValue("payment.vendorTerms", [defaultTerm]);
            }
        }
    }, [vendorTerms]); // Only run when `vendorTerms` changes

    const selectedVendorTerm = getFormikField(formik, "payment.vendorTerms").value

    return (
        <>
            <Typography color="primary">{tAddV("VENDOR DETAILS")}</Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <Grid container spacing={2}>
                <Grid {...gridItemProps}>
                    <TextField required variant="filled" {...textFieldProps} label={tAddV("Company Name")} name="details.companyName" disabled={viewMode}
                        error={Boolean(getFormikField(formik, "details.companyName").error)}
                        helperText={getFormikField(formik, "details.companyName").error}
                        value={getFormikField(formik, "details.companyName").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("Account Number")} required name="details.accountNumber" disabled={viewMode}
                        error={Boolean(getFormikField(formik, "details.accountNumber").error)}
                        helperText={getFormikField(formik, "details.accountNumber").error}
                        value={getFormikField(formik, "details.accountNumber").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("Address")} name="details.address" disabled={viewMode}
                        error={Boolean(getFormikField(formik, "details.address").error)}
                        helperText={getFormikField(formik, "details.address").error}
                        value={getFormikField(formik, "details.address").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("EIN Number")} name="details.employerIdentificationNumber" disabled={viewMode}
                        error={Boolean(getFormikField(formik, "details.employerIdentificationNumber").error)}
                        helperText={getFormikField(formik, "details.employerIdentificationNumber").error}
                        value={getFormikField(formik, "details.employerIdentificationNumber").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("City")} name="details.city" disabled={viewMode}
                        error={Boolean(getFormikField(formik, "details.city").error)}
                        helperText={getFormikField(formik, "details.city").error}
                        value={getFormikField(formik, "details.city").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("Website")} name="details.website" disabled={viewMode}
                        error={Boolean(getFormikField(formik, "details.website").error)}
                        helperText={getFormikField(formik, "details.website").error}
                        value={getFormikField(formik, "details.website").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("State")} name="details.state" disabled={viewMode} select
                        error={Boolean(getFormikField(formik, "details.state").error)}
                        helperText={getFormikField(formik, "details.state").error}
                        value={getFormikField(formik, "details.state").value} onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {usaStates.map((state) => (
                            <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                {state.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("Email")} name="details.email" disabled={viewMode}
                        error={Boolean(getFormikField(formik, "details.email").error)}
                        helperText={getFormikField(formik, "details.email").error}
                        value={getFormikField(formik, "details.email").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("Zip Code")} name="details.zipCode" disabled={viewMode}
                        error={Boolean(getFormikField(formik, "details.zipCode").error)}
                        helperText={getFormikField(formik, "details.zipCode").error}
                        value={getFormikField(formik, "details.zipCode").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("Tel. No.")} name="details.phone" disabled={viewMode}
                        error={Boolean(getFormikField(formik, "details.phone").error)}
                        helperText={getFormikField(formik, "details.phone").error}
                        value={getFormikField(formik, "details.phone").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid {...gridItemProps}>
                    <TextField disabled variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("Country")} name="details.country" select
                        error={Boolean(getFormikField(formik, "details.country").error)}
                        helperText={getFormikField(formik, "details.country").error}
                        value={getFormikField(formik, "details.country").value} onChange={formik.handleChange}
                    >
                        <MenuItem value="US">US</MenuItem>
                    </TextField>
                </Grid>
                <Grid {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("Preferred Method of Communication")} name="details.preferredCommunication" disabled={viewMode}
                        error={Boolean(getFormikField(formik, "details.preferredCommunication").error)}
                        helperText={getFormikField(formik, "details.preferredCommunication").error}
                        value={getFormikField(formik, "details.preferredCommunication").value} onChange={formik.handleChange}
                    />
                </Grid>
            </Grid>
            <Typography color="primary" sx={{ marginTop: 2 }} >{tAddV("PAYMENT INFORMATION")}</Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <Stack gap={2} direction={"row"} justifyContent={"space-around"} width={"100%"}>
                <Stack gap={2} width={"100%"}>
                    <Grid {...gridItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={tAddV("Current Balance")} name="payment.currentBalance" disabled={viewMode} type="number"
                            error={Boolean(getFormikField(formik, "payment.currentBalance").error)}
                            helperText={getFormikField(formik, "payment.currentBalance").error}
                            value={getFormikField(formik, "payment.currentBalance").value} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid {...gridItemProps}>
                        <TextField variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tAddV("Payment Terms")} select name="payment.vendorTerms" disabled={viewMode}
                            error={Boolean(getFormikField(formik, "payment.vendorTerms").error)}
                            helperText={getFormikField(formik, "payment.vendorTerms").error}
                            value={Array.isArray(selectedVendorTerm) && selectedVendorTerm.length > 0 ? selectedVendorTerm[0].VendorTermId : ""} // Check for VendorTermId or default to ""
                            onChange={(event) => {
                                const selectedTerm = vendorTerms.find((vT) => vT.VendorTermId === event.target.value);
                                formik.setFieldValue("payment.vendorTerms", selectedTerm ? [selectedTerm] : []);
                            }}
                        >
                            <MenuItem value={""}>{tGC("None")}</MenuItem>
                            {vendorTerms.map((vT) => {
                                return (
                                    <MenuItem key={vT.VendorTermId} value={vT.VendorTermId}>
                                        {vT.name}
                                    </MenuItem>
                                )
                            }
                            )}
                        </TextField>
                    </Grid>
                    <Grid {...gridItemProps}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                disabled={viewMode}
                                label="Last Order Date"
                                {...textFieldProps}
                                value={lastOrderDate}
                                onChange={handleChangeLastOrderDate}
                                views={["year", "month", "day"]}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Stack>
                <Stack gap={2} width={"100%"} className="flex justify-self-center">
                    {/* <Grid {...gridItemProps}>
                            <FormControlLabel
                             {...textFieldProps}
                                control={
                                    <Checkbox
                                        value={Boolean(getFormikField(formik, "payment.rebates").value)}
                                    />
                                }
                                label={tAddV("Rebates")} name="payment.rebates"
                                onChange={formik.handleChange}
                            />
                    </Grid>
                    <Grid {...gridItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={tAddV("Percentage")} type="number" name="payment.percentage"
                            error={Boolean(getFormikField(formik, "payment.percentage").error)}
                            helperText={getFormikField(formik, "payment.percentage").error}
                            value={getFormikField(formik, "payment.percentage").value} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid {...gridItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={tAddV("Minimum")} type="number" name="payment.minimum"
                            error={Boolean(getFormikField(formik, "payment.minimum").error)}
                            helperText={getFormikField(formik, "payment.minimum").error}
                            value={getFormikField(formik, "payment.minimum").value} onChange={formik.handleChange}
                        />
                    </Grid> */}
                    <Grid {...gridItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={tAddV("Yearly Purchase Volume")} disabled type="number" name="payment.volume"
                            error={Boolean(getFormikField(formik, "payment.volume").error)}
                            helperText={getFormikField(formik, "payment.volume").error}
                            value={getFormikField(formik, "payment.volume").value} onChange={formik.handleChange}
                        />
                    </Grid>
                </Stack>
            </Stack>
        </>
    );
}
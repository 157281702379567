import "immer"
import { createSlice, PayloadAction} from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { Vendor, VendorSearchOutput, VendorSearchInput } from "orderflow-lambdas"
import ApiClient from "../../util/api-client"
type SearchBy = VendorSearchInput["searchBy"]

export interface VendorBrowseState {
  searchInput: string
  results?: VendorSearchOutput
  searchBy: SearchBy
  loading: boolean,
  activeSelection?: Vendor
}

// Initialize state
const initialState: VendorBrowseState = {
  searchInput: "",
  results: undefined,
  searchBy: "vendorName",
  loading: false,
  activeSelection: undefined
}

export const vendorBrowseSlice = createSlice({
  name: "vendorBrowse",
  initialState,
  reducers: {
    setSearchInput: (state, action: PayloadAction<string>) => ({
      ...state,
      searchInput: action.payload
    }),
    setSearchBy: (state, action: PayloadAction<SearchBy>) => ({
      ...state,
      searchBy: action.payload
    }),
    setResults: (state, action: PayloadAction<VendorSearchOutput | undefined>) => ({
      ...state,
      results: action.payload
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload
    }),
    setActiveSelection: (state, action: PayloadAction<Vendor>) => ({
      ...state,
      activeSelection: action.payload
    })
  },
})

export const { 
  setSearchInput,
  setSearchBy,
  setResults,
  setLoading,
  setActiveSelection
} = vendorBrowseSlice.actions
export default vendorBrowseSlice.reducer

// Thunks
export const searchVendors = (searchInput: VendorSearchInput) => async (dispatch: Dispatch): Promise<void> => {
  try {
      dispatch(setLoading(true))
      const results = await ApiClient.searchVendors.query(searchInput)
      dispatch(setResults(results))
  } catch (error: unknown) {
      dispatch(setResults(undefined))
  } finally {
      dispatch(setLoading(false))
  }
}

import { Box, Typography, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { buttonWidth } from '../../constants/form';
import { Routes } from '../../../../util/constants';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../../state';
import { closeModal } from '../../../../state/orderPopupModalSlice';
import { useTranslation } from 'react-i18next';
import { openOrderModal } from '../../../../state/orderModalSlice';
import { popupFormWidth } from './constants';



export default function CreateOrderPopupModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: 'team.Global.Buttons' })
    const { t: tCreateC } = useTranslation([], { keyPrefix: 'team.Modals.Orders.createAndEdit.Create Order' })

    const { openOrderPopupModal } = useSelector(({ orderPopupModalSlice }) => orderPopupModalSlice);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeModal());
    };

    const handleOpenCreateOrderPopupModal = () => {
        handleClose();
        dispatch(openOrderModal({resetState: true}))
    };

    return (
        <Dialog
            open={openOrderPopupModal}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick')
                    return;
                handleClose()
            }}
            maxWidth='lg'
            scroll='paper'
            PaperProps={{ style: { width: popupFormWidth } }}
        >
            <>
                <DialogTitle sx={{ m: 0, p: 1 }}>{tCreateC('Create Order')}</DialogTitle>
                <IconButton color='primary' sx={{ position: 'absolute', right: 4, top: 4 }} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ p: 1 }} >
                    <Typography align='center'>{tCreateC('To avoid duplicate, it is suggested to search for existing orders.')}</Typography>
                    <Typography align='center'>{tCreateC('Search for existing orders?')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Box width='100%' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <Button variant='contained' color='primary' sx={{ width: buttonWidth, marginRight: 2 }} onClick={() => { handleOpenCreateOrderPopupModal() }}>{tGlobal('Create')}</Button>
                            <Button variant='outlined' color='primary' sx={{ width: buttonWidth }} onClick={() => {
                                navigate(Routes.HOME_ORDERS)
                                handleClose()
                            }}>{tGlobal('Search')}</Button>
                        </Box>
                        <Button variant='outlined' color='primary' sx={{ width: buttonWidth }} onClick={handleClose} >{tGlobal('Cancel')}</Button>
                    </Box>
                </DialogActions>
            </>
        </Dialog >
    );
}

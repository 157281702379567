import { useTranslation } from "react-i18next";
import  Tables  from "../../components/OrderBrowseTable";

export default function Customers() {
  const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
  return (
    <>
      <div className="flex flex-col justify-center mx-10 my-12 border border-gray-200 border-solid rounded shadow-xl md:mx-20">
        <h2 className="pt-4 pl-4 pr-4 text-blue-600">{tGC("Browse Orders")}</h2>
        <hr className="mx-5" />
          <Tables/>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Button, IconButton, Container, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VendorAndPayment from "./VendorAndPayment";
import ReceivableAndShipping from "./ReceivableAndShipping";
import ContactAndNotes from "./ContactAndNotes";
import { buttonWidth } from "../../constants/form";
import { useFormik } from "formik";
import { vendorSchema } from "./schemas/indexSchema";
import { client } from "../../../../util";
import type { Vendor, VendorCreate, VendorTerms } from "orderflow-lambdas";
import { pushToast, useDispatch, useSelector } from "../../../../state";
import { closeModal, fetchVendorToEdit, setLoading, toggleViewMode } from "../../../../state/vendorModalSlice";
import { useTranslation } from "react-i18next";
import { TRPCClientError } from "@trpc/client";
import { serverError } from "../../constants/errors";
import { serverLatencyMessage } from "../../constants/messages";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const initialValues: VendorCreate = {
    details: {
        companyName: "",
        accountNumber: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        country: "US",
        employerIdentificationNumber: "",
        website: "",
        email: "",
        phone: "",
        preferredCommunication: "",
        other: "",
    },
    receivable: {
        companyName: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        country: "US",
        contactName: "",
        email: "",
        phone: "",
        preferredCommunication: "",
    },
    shipping: {
        address: "",
        companyName: "",
        city: "",
        state: "",
        zipCode: "",
        country: "US",
        email: "",
        phone: "",
        contactName: "",
        preferredCommunication: "",
    },
    showNotes: false,
    payment: {
        minimum: 0,
        rebates: false,
        percentage: 0,
        vendorTerms: [],
        currentBalance: 0,
        lastOrderDate: 0,
        volume: 0,
    },
    notes: "",
    contacts: [],
};


export default function CreateEditVendorModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tAddC } = useTranslation([], { keyPrefix: "team.Modals.Vendors.createAndEdit.Add Vendor" })

    const { viewMode, openVendorModal, isEditingVendor, vendorIdToEdit, updateCallback, createCallback, loading } = useSelector(({ vendorModalSlice }) => vendorModalSlice);
    const { team } = useSelector(({ userSlice }) => userSlice);

    const [tab, setTab] = useState(0);

    const [vendorTerms, setVendorTerms] = useState<VendorTerms[]>([]);

    const dispatch = useDispatch();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const resetState = () => {
        setTab(0);
        formik.resetForm()
    };

    const handleClose = () => {
        dispatch(closeModal());
        resetState();
    };

    const updateLoading = (value: boolean) => {
        dispatch(setLoading(value))
    }

    const handleToggleViewMode = async () => {
        dispatch(toggleViewMode())
    }

    const handleSave = async (payload: VendorCreate) => {
        updateLoading(true)
        if (isEditingVendor) { // check if modal is in edit mode
            if (vendorIdToEdit) { // double check if id exists. Should never happen.
                try {
                    const updatedVendor = await client.updateVendor.mutate({
                        ...payload,
                        VendorId: vendorIdToEdit
                    });
                    if (updateCallback) updateCallback(updatedVendor)
                    dispatch(pushToast({
                        message: `Vendor ${updatedVendor?.details.companyName || ""} updated`,
                        additionalInfo: serverLatencyMessage,
                        type: "success", duration: 7000
                    }));
                } catch (error) {
                    console.error("error", error);
                    if (error instanceof TRPCClientError) {
                        dispatch(pushToast({ message: error.message, type: "error" }));
                    } else {
                        dispatch(pushToast({ message: serverError, type: "error" }));
                    }
                }
            } else {
                // this should never happaned but its good to have this
                console.log("ERROR: Vendor id is wrong or missing: ", vendorIdToEdit)
                dispatch(pushToast({ message: serverError, type: "error" }));
            }

        } else {
            try {
                if (team === "init" || team === "loading") return
                const newVendor: Vendor = await client.createVendor.mutate({
                    ...payload,
                });
                if (createCallback) createCallback(newVendor)
                dispatch(pushToast({
                    message: `Vendor ${newVendor?.details.companyName || ""} created`,
                    additionalInfo: serverLatencyMessage,
                    type: "success", duration: 7000
                }));
            }
            catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
            }
        }
        handleClose()

        updateLoading(false)
    }

    // Formik initialization
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: vendorSchema,
        onSubmit: handleSave,
    });

    useEffect(() => {
        const fetchData = async () => {
            updateLoading(true)
            if (vendorIdToEdit) {
                try {
                    const vendor = await dispatch(fetchVendorToEdit(vendorIdToEdit))
                    formik.setValues({
                        ...formik.values,  // Keeping any other existing form values
                        ...vendor        // Seting vendor data returned from API
                    });
                } catch (error) {
                    handleClose()

                    if (error instanceof TRPCClientError) {
                        dispatch(pushToast({ message: error.message, type: "error" }));
                    } else {
                        dispatch(pushToast({ message: serverError, type: "error" }));
                    }
                }
            }
            updateLoading(false);
        };
        fetchData();
    }, [vendorIdToEdit]);

    useEffect(() => {
        if (!openVendorModal) return;
        const fetchData = async () => {
            updateLoading(true)
            // Get Vendor terms
            try {
                const resp = await client.allVendorTerms.query();
                setVendorTerms(resp.results)
            } catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
                handleClose()
            }
            updateLoading(false);
        };
        fetchData();
    }, [openVendorModal]);

    return (
        <Dialog
            open={openVendorModal}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick")
                    return;
                handleClose()
            }}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{ className: "w-full" }}
        >
            <DialogTitle sx={{ m: 0, p: 1 }}>{isEditingVendor ? tAddC("Edit Vendor") : tAddC("Add Vendor")}</DialogTitle>
            <IconButton disabled={loading} color="primary" sx={{ position: "absolute", right: 4, top: 4 }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 1 }} >
                {loading ? (
                    <Box height="400px" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={80} />
                    </Box>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <Container>
                            <Tabs centered value={tab} onChange={handleTabChange} sx={{
                                "& .MuiTabs-flexContainer": {
                                    justifyContent: "space-between",
                                },
                            }}>
                                <Tab sx={{ fontWeight: "bold" }} label="Vendor Details & Payment Information" {...a11yProps(0)} />
                                <Tab sx={{ fontWeight: "bold" }} label="Accounts Receivable & Shipping" {...a11yProps(1)} />
                                <Tab sx={{ fontWeight: "bold" }} label="Contacts & Notes" {...a11yProps(2)} />
                            </Tabs>
                        </Container>
                        <TabPanel value={tab} index={0}>
                            <VendorAndPayment formik={formik} vendorTerms={vendorTerms} viewMode={viewMode} />
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <ReceivableAndShipping formik={formik} viewMode={viewMode}  />
                        </TabPanel>
                        <TabPanel value={tab} index={2}>
                            <ContactAndNotes formik={formik}viewMode={viewMode}  />
                        </TabPanel>
                    </form>
                )}
            </DialogContent>
            <DialogActions>
                <Box width="100%" sx={{ display: "flex", justifyContent: "space-between" }}>
                    {viewMode ? (
                        <Button disabled={loading} variant="contained" color="primary" sx={{ width: buttonWidth }} onClick={() => {
                            handleToggleViewMode()
                        }}>{loading ? <CircularProgress size={20} /> : tGlobal("Edit")}</Button>) : (
                        <Button disabled={loading} variant="contained" color="primary" sx={{ width: buttonWidth }} onClick={() => {
                            formik.submitForm()
                        }}>{loading ? <CircularProgress size={20} /> : tGlobal("Save")}</Button>
                    )}
                    <Button disabled={loading} variant="outlined" color="primary" sx={{ width: buttonWidth }} onClick={handleClose}>{tGlobal("Cancel")}</Button>
                </Box>
            </DialogActions>
        </Dialog >
    );
}

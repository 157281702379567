import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { client } from '../../util/api-client';
import { Customer, CustomerSearchInput } from 'orderflow-lambdas';
import { debounce } from '@mui/material/utils'

interface Props {
  label: string;
  value: Customer | null;
  onChange: (customer: Customer | null) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean
}

const SelectSearchCustomer: React.FC<Props> = ({ label, value, onChange, error, helperText, disabled }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly Customer[]>([]);
  const [loading, setLoading] = useState(false);


  const serchCustomers = async (searchQuery: string) => {
    const payload: CustomerSearchInput = { query: searchQuery, searchBy: 'companyName', size: 20, page: 0 }
    const resp = await client.searchCustomers.query(payload);
    setOptions(resp.results);
    setLoading(false);
  }

  const handleOpen = () => {
    setOpen(true);
    (async () => {
      setLoading(true);
      await serchCustomers("")
      setLoading(false);
    })();
  };

  const debouncedSearch = React.useCallback(
    debounce((searchQuery: string) => {
      serchCustomers(searchQuery);
    }, 2000),
    []
  );

  const onInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setLoading(true);
    debouncedSearch(value)
  }

  const handleClose = () => {
    setOpen(false);
    setOptions([]);
  };
  return (
    <Autocomplete
      disabled={disabled}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      value={value}
      isOptionEqualToValue={(option, value) => option.details.companyName === value.details.companyName}
      getOptionLabel={(option) => option.details.companyName}
      options={options}
      loading={loading}
      onInputChange={onInputChange}
      onChange={(e, v) => { onChange(v) }}
      renderInput={(params) => (
        <TextField
          error={error}
          helperText={helperText}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
export default SelectSearchCustomer;

export const buttonWidth = "104px";
export const selectMaxHeight = 300;

export const maxFieldLength = 255

export const textFieldProps = {
    sx: {
        maxWidth: "300px", width: "100%",
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "black",
        },
    },
    autoComplete: "off",
};

export const wideTextFieldProps = {
    sx: { maxWidth: "860px", width: "100%" },
    autoComplete: "off"
};

export const gridItemProps = {
    item: true,
    xs: 12,
    sm: 6,
    sx: { display: "flex", justifyContent: "center" },
};

export const textFieldSelectProps = {
    SelectProps: {
        MenuProps: {
            PaperProps: {
                style: {
                    maxHeight: selectMaxHeight,
                }
            }
        }
    }
};
import { Typography, TextField, MenuItem, Divider, Button, Box } from "@mui/material";
import type { VendorCreate } from "orderflow-lambdas";
import usaStates from "../../../../static/usaStates.json";
import { textFieldProps, textFieldSelectProps } from "../../constants/form";
import { useEffect, useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import { FormikProps } from "formik";
import { getFormikField } from "../../../../util/fornik-helpers";
import { useTranslation } from "react-i18next";

export default function ReceivableAndShipping({
    formik,
    viewMode,
}: {
    formik: FormikProps<VendorCreate>;
    viewMode: boolean;
}): JSX.Element {
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tAddV } = useTranslation([], { keyPrefix: "team.Modals.Vendors.createAndEdit.Add Vendor" })
    const { t: tAddVButtons } = useTranslation([], { keyPrefix: "team.Modals.Vendors.createAndEdit.Add Vendor.Buttons" })

    const [receivableCopied, setReceivableCopied] = useState(false);
    const [shippingCopied, setShippingCopied] = useState(false);

    const copyVendorIntoReceivable = () => {
        const { other, website, ...vendorData } = formik.values.details; // removing unneeded fields

        formik.setValues({
            ...formik.values,
            receivable: { ...vendorData }
        });
    };

    const copyVendorIntoShipping = () => {
        const { other, website, ...vendorData } = formik.values.details; // removing unneeded fields

        formik.setValues({
            ...formik.values,
            shipping: { ...vendorData }
        });
    };

    useEffect(() => {
        // Watching the receivable and shipping objects and compare them to the vendor object
        const { other, website, ...vendorData } = formik.values.details; // removing unneeded fields. Its important do do it here too so if statments work good when compare data
        const receivableData = formik.values.receivable;
        const shippingData = formik.values.shipping;

        if (receivableCopied && JSON.stringify(receivableData) !== JSON.stringify(vendorData)) {
            setReceivableCopied(false);
        }
        if (shippingCopied && JSON.stringify(shippingData) !== JSON.stringify(vendorData)) {
            setShippingCopied(false);
        }
    }, [formik.values.receivable, formik.values.shipping, formik.values.details, receivableCopied, shippingCopied]);


    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "2px" }}>
                <Typography color="primary">{tAddV("ACCOUNTS RECEIVABLE DETAILS")}</Typography>
                {receivableCopied ? (
                    <Button disabled={viewMode} variant="outlined" size="small" color="success" startIcon={<CheckIcon />}>{tAddVButtons("Copied")}</Button>
                ) : (
                    <Button disabled={viewMode} variant="outlined" size="small" color="primary" onClick={() => { copyVendorIntoReceivable(); setReceivableCopied(true) }}>{tAddVButtons("Copy VENDOR Details")}</Button>
                )}
            </Box >
            <Divider sx={{ marginBottom: 1 }} />
            <div className="grid grid-flow-col grid-rows-6 gap-4 webkit-box">
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tAddV("Company Name")} name="receivable.companyName" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'receivable.companyName').error)}
                        helperText={getFormikField(formik, 'receivable.companyName').error}
                        value={getFormikField(formik, 'receivable.companyName').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Address")} name="receivable.address" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'receivable.address').error)}
                        helperText={getFormikField(formik, 'receivable.address').error}
                        value={getFormikField(formik, 'receivable.address').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("City")} name="receivable.city" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'receivable.city').error)}
                        helperText={getFormikField(formik, 'receivable.city').error}
                        value={getFormikField(formik, 'receivable.city').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("State")} select name="receivable.state" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'receivable.state').error)}
                        helperText={getFormikField(formik, 'receivable.state').error}
                        value={getFormikField(formik, 'receivable.state').value} onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {usaStates.map((state) => (
                            <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                {state.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Zip")} name="receivable.zipCode" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'receivable.zipCode').error)}
                        helperText={getFormikField(formik, 'receivable.zipCode').error}
                        value={getFormikField(formik, 'receivable.zipCode').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField disabled variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("Country")} select name="receivable.country"
                        error={Boolean(getFormikField(formik, 'receivable.country').error)}
                        helperText={getFormikField(formik, 'receivable.country').error}
                        value={getFormikField(formik, 'receivable.country').value} onChange={formik.handleChange}
                    >
                        <MenuItem value="US">US</MenuItem>
                    </TextField>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Contact Name")} name="receivable.contactName" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'receivable.contactName').error)}
                        helperText={getFormikField(formik, 'receivable.contactName').error}
                        value={getFormikField(formik, 'receivable.contactName').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Tel. No.")} name="receivable.phone" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'receivable.phone').error)}
                        helperText={getFormikField(formik, 'receivable.phone').error}
                        value={getFormikField(formik, 'receivable.phone').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Email")} name="receivable.email" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'receivable.email').error)}
                        helperText={getFormikField(formik, 'receivable.email').error}
                        value={getFormikField(formik, 'receivable.email').value} onChange={formik.handleChange}
                    />
                </div>
            </div>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 2, marginBottom: "2px" }}>
                <Typography color="primary">{tAddV("SHIPPING CONTACT  DETAILS")}</Typography>
                {shippingCopied ? (
                    <Button disabled={viewMode} variant="outlined" size="small" color="success" startIcon={<CheckIcon />}>{tAddVButtons("Copied")}</Button>
                ) : (
                    <Button disabled={viewMode} variant="outlined" size="small" color="primary" onClick={() => { copyVendorIntoShipping(); setShippingCopied(true) }}>{tAddVButtons("Copy VENDOR Details")}</Button>
                )}
            </Box >
            <Divider sx={{ marginBottom: 1 }} />
            <div className="grid grid-flow-col grid-rows-6 gap-4 webkit-box">
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tAddV("Company Name")} name="shipping.companyName" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'shipping.companyName').error)}
                        helperText={getFormikField(formik, 'shipping.companyName').error}
                        value={getFormikField(formik, 'shipping.companyName').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Address")} name="shipping.address" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'shipping.address').error)}
                        helperText={getFormikField(formik, 'shipping.address').error}
                        value={getFormikField(formik, 'shipping.address').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("City")} name="shipping.city" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'shipping.city').error)}
                        helperText={getFormikField(formik, 'shipping.city').error}
                        value={getFormikField(formik, 'shipping.city').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("State")} select name="shipping.state" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'shipping.state').error)}
                        helperText={getFormikField(formik, 'shipping.state').error}
                        value={getFormikField(formik, 'shipping.state').value} onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {usaStates.map((state) => (
                            <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                {state.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Zip")} name="shipping.zipCode" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'shipping.zipCode').error)}
                        helperText={getFormikField(formik, 'shipping.zipCode').error}
                        value={getFormikField(formik, 'shipping.zipCode').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField disabled variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("Country")} select name="shipping.country"
                        error={Boolean(getFormikField(formik, 'shipping.country').error)}
                        helperText={getFormikField(formik, 'shipping.country').error}
                        value={getFormikField(formik, 'shipping.country').value} onChange={formik.handleChange}
                    >
                        <MenuItem value="US">US</MenuItem>
                    </TextField>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Contact Name")} name="shipping.contactName" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'shipping.contactName').error)}
                        helperText={getFormikField(formik, 'shipping.contactName').error}
                        value={getFormikField(formik, 'shipping.contactName').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Tel. No.")} name="shipping.phone" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'shipping.phone').error)}
                        helperText={getFormikField(formik, 'shipping.phone').error}
                        value={getFormikField(formik, 'shipping.phone').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Email")} name="shipping.email" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'shipping.email').error)}
                        helperText={getFormikField(formik, 'shipping.email').error}
                        value={getFormikField(formik, 'shipping.email').value} onChange={formik.handleChange}
                    />
                </div>
            </div>
        </>
    );
}
